import React, { useImperativeHandle, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import FilterDropdown, { IFilterItem } from '../shared/FilterDropdown/filterDropdown';

import './resourcesFilterBar.scss';

export interface IResourceFilters {
    topic: string;
}

interface IProps {
    resourceTopics: IFilterItem[];
    onFilterChanged?: any;
}

const ResourcesFilterBar = React.forwardRef((props: IProps, ref: any) => {
    const { resourceTopics } = props;

    const init: IResourceFilters = {
        topic: resourceTopics[0].key
    };

    const [ filters, setFilters ] = useState(init);

    useImperativeHandle(ref, () => (
        {
            getFilters: () => {
                return filters;
            }
        })
    );
    
    const setFilter = (filter: any) => {
        const newFilters = {...filters, ...filter};
        setFilters(newFilters);
        props.onFilterChanged(newFilters);
    };

    return (
        <div className="res-filter-bar">
            <FilterDropdown title="Topic" items={resourceTopics} onItemClicked={(i) => setFilter({ topic: resourceTopics[i].key })} />
        </div>
    );
});

export default ResourcesFilterBar;