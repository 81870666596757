import React, { useRef, useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { Card, Col, Container, Row } from "react-bootstrap";
import { graphql, Link } from "gatsby";

import { IResource } from "../models/cmsModels";
import Layout from "../components/Layout";
import Resource from "../components/Resource/resource";
import { StaticImage } from "gatsby-plugin-image"
import ResourcesFilterBar, { IResourceFilters } from "../components/ResourcesFilterBar/resourcesFilterBar";
import { IFilterItem } from "../components/shared/FilterDropdown/filterDropdown";

import '../helpers/fontawesome';
import "../assets/css/main.scss";
import SEO from "../components/seo";

const Resources = ({data}) => {
  const allResources = data.resources.nodes;
  const [resources, setResources] = useState(allResources);

  const { resourcesTitle, resourcesDescription } = data?.seo?.nodes[0];

  const [link, setLink] = useQueryParam("link", StringParam);

  const filtersRef = useRef(null);

  const onFilterChanged = (fl: IResourceFilters) => {
    setResources(allResources.filter((res: IResource) => !fl.topic || (res.topic === fl.topic)));
  };

  let resourceTopics: IFilterItem[] = [{ key: '', label: 'All' }];
  allResources.forEach((res: IResource) => {
    if (!resourceTopics.find((topic: IFilterItem) => topic.key === res.topic )) {
      resourceTopics.push({ key: res.topic, label: res.topic });
    }
  });

  return (
    <Layout>
      <SEO title={resourcesTitle} description={resourcesDescription}></SEO>
      {
        link
        ? <Resource link={link} setLink={(link: string) => setLink(link)} resources={resources} />
        :
          <Container className="resources fl-page">
            <div className="rs-heading row mb-3">
              <h3>Florabank Resources</h3>
            </div>

            <Row>
              <Col sm={{span: 5, offset: 7}} md={{span: 4, offset: 8}} lg={{span: 3, offset: 9}}>
                <ResourcesFilterBar ref={filtersRef} resourceTopics={resourceTopics} onFilterChanged={ onFilterChanged } />
              </Col>
            </Row>

            <Row>
              {
                !resources ? <></> :
                resources.map((resource: IResource, r: number) => {
                  const { title, summary, image, topic, link } = resource;
                  
                  return (
                    <Card key={r}>
                      <Card.Body>
                        <Row>
                          <Col md={1}>
                            { image && <img className="rs-img" alt={title} src={image.publicURL} /> }
                          </Col>
                          <Col md={5}>
                            <Link to={`?link=${link}`}>
                              <div className="rs-col rs-title">
                                {title}
                              </div>
                            </Link>
                          </Col>
                          <Col md={4}><div className="rs-col">{summary}</div></Col>
                          <Col xs={1}><div className="rs-col">{topic}</div></Col>
                          {
                            !resource.pdfFile ? <></> :
                              <Col xs={{ span: 1, offset: 9 }} md={{ span: 1, offset: 0 }}>
                                <div className="rs-col">
                                  <a href={resource.pdfFile.publicURL} className="download-link" target="_blank" title="View/Download pdf">
                                    <StaticImage className="rs-pdf" src="../assets/images/download-pdf.png" alt="" />
                                  </a>
                                </div>
                              </Col>
                          }
                        </Row>
                      </Card.Body>
                    </Card>
                  );
                })
              }
            </Row>
          </Container>
      }
    </Layout>
  );
};

export const query = graphql`
  query Resources {
    resources: allStrapiResource {
      nodes {
        id
        title
        summary
        topic
        link
        contents
        sections {
          title
          link
        }
        image {
          publicURL
        }
        pdfFile {
          publicURL
        }
      }
    },
    seo: allStrapiSeo {
      nodes {
        resourcesDescription
        resourcesTitle
      }
    },
  }
`;

export default Resources;