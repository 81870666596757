import React, { useEffect, useRef, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { IResource } from "../../models/cmsModels";
import Sections from "../shared/Sections/Sections";

import './resource.scss';

interface IProps {
  link: string,
  setLink: any,
  resources: any
}

const resource = (props: IProps) => {
  const [resources, setData] = useState(undefined as IResource[]);
  const [isRendered, setRendered] = useState(false);

  const childRef = useRef();

  let resource: IResource = {} as IResource;

  useEffect(() => {
    const fn = async () => {
      if (!resources) {
        let list: IResource[] = props.resources;
        setData(list);
      }
    };

    if (!isRendered) {
      const id = location.hash.substr(1);
      const element = document.getElementById(id);
   
      if (element) {
        element.scrollIntoView();
        setRendered(true);
      }
    }

    fn();
  });

  if (resources && resources.length) {
    if (props.link) {
      resource = resources.find((res: IResource) => res.link === props.link);
      if (!resource) resource = resources[0];
    }
    else {
      resource = resources[0];
    }
  }

  return (
    <div className="resource">
      <div className="res-contents-panel">
        <h2>{resource.title}</h2>
        <div dangerouslySetInnerHTML={{__html: resource.contents}}></div>
        {
          !resource.sections ? <></> :
          <Sections sections={resource.sections} />
        }
      </div>
    </div>
  );
};

export default resource;